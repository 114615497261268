// BASE pack. Include base stuff (rails ujs, turbolinks, jquery, theme)
// Anything app-specific should be in app.js/app.scss

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');

import '@fortawesome/fontawesome-free/js/all';
import 'flag-icons';
import '@hotwired/turbo';
